import React, { Component } from 'react';
import './App.css';
import Navigation from './containers/navigation';
import MainContainer from './containers/main';
import Footer from './containers/footer';

class App extends Component {
    loader;
    constructor(props) {
        super(props);
        this.state = {
            showLoader: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ showLoader: false });
        }, 2000);
    }


    render() {
        return (
            <>
                {this.state.showLoader ?
                    <div id="preloader" ref={(loader) => { this.loader = loader }}>
                        <div className="loader" id="loader-1"></div>
                    </div> : null
                }
                <Navigation></Navigation>
                <MainContainer></MainContainer>
                <Footer></Footer>
            </>
        );
    }
}

export default App;
