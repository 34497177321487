import React from "react";

const Feature = (props) => {
    return (
        <div className="col-md-6 col-lg-4 col-xs-12">
            <div className="services-item wow fadeInRight" data-wow-delay="0.6s">
                <div className="icon">
                    <i className={props.feature.icon}></i>
                </div>
                <div className="services-content">
                    <h3><a href="./">{props.feature.header}</a></h3>
                    <p>{props.feature.contents}</p>
                </div>
            </div>
        </div>
    );
}


export default Feature;