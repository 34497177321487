import React from "react";
import Feature from "./feature";
const MainContainer = (props) => {
    const featuresList = [
        {
            header: "Find email addresses",
            contents: `Profile locator helps you find any business email for you`,
            icon: "lni-envelope"
        },
        {
            header: "Instant email sender",
            contents: "Click away to send email to your prospects",
            icon: "lni-bullhorn"
        },
        {
            header: "Email Campaign Scheduler",
            contents: "Schedule email campaigns for follow-ups to generate leads",
            icon: "lni-timer"
        },
        {
            header: "Cloud Storage",
            contents: "Maintain all leads on cloud. Allowing you to move faster and be more flexible while prospecting.",
            icon: "lni-cloud-check"
        },
        {
            header: "Professional Email Search",
            contents: "Find and save email addresses of LinkedIn-ers! Do a bare search on LinkedIn to find just the profiles you're seeking.",
            icon: "lni-search"
        },
    ];
    return (
        <>
            <section id="feature" className="section-padding">
                <div className="container">
                    <div className="section-header text-center">
                        <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Features</h2>
                    </div>
                    <div className="row">
                        {
                            featuresList.map((e, i) => {
                                return <Feature key={e.header + "_" + i} feature={e}></Feature>
                            })
                        }
                    </div>
                </div>
            </section>
            <div id="feature">
                <div className="container-fluid">
                    <div className="row" style={{ "marginBottom": '20px' }}>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text-wrapper">
                                <div>
                                    <h2 className="title-hl wow fadeInLeft" data-wow-delay="0.3s">Prospect is just a click away </h2>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="features-box wow fadeInLeft" data-wow-delay="0.3s">
                                                <div className="features-icon">
                                                    <i className="lni-layers"></i>
                                                </div>
                                                <div className="features-content">
                                                    <h4>Complete Your Search</h4>
                                                    <p>Extension can help you to complete any prospects profile.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 padding-none">
                            <div className="wow fadeInRight tcenter" data-wow-delay="0.3s">
                                <img src="assets/img/extension.jpg" width="70%" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ "marginBottom": '20px' }}>
                        <div className="col-lg-6 col-md-12 col-sm-12 padding-none">
                            <div className="wow fadeInRight tcenter" data-wow-delay="0.3s">
                                <img src="assets/img/extension3.jpg" width="70%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text-wrapper">
                                <div>
                                    <h2 className="title-hl wow fadeInLeft" data-wow-delay="0.3s">Learn More About Our Extension</h2>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="features-box wow fadeInLeft" data-wow-delay="0.3s">
                                                <div className="features-icon">
                                                    <i className="lni-layers"></i>
                                                </div>
                                                <div className="features-content">
                                                    <h4> Easy Prospecting</h4>
                                                    <p>List down your prospects and add them in your targeted list.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ "marginBottom": '20px' }}>

                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text-wrapper">
                                <div>
                                    <h2 className="title-hl wow fadeInLeft" data-wow-delay="0.3s">Build a directory of leads</h2>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="features-box wow fadeInLeft" data-wow-delay="0.3s">
                                                <div className="features-icon">
                                                    <i className="lni-layers"></i>
                                                </div>
                                                <div className="features-content">
                                                    <h4>Cloud Storage</h4>
                                                    <p>Login anywhere and get access to your leads.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 padding-none">
                            <div className="wow fadeInRight tcenter" data-wow-delay="0.3s">
                                <img src="assets/img/extension4.jpg" width="70%" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ "marginBottom": '20px' }}>
                        <div className="col-lg-6 col-md-12 col-sm-12 padding-none">
                            <div className="wow fadeInRight tcenter" data-wow-delay="0.3s">
                                <img src="assets/img/extension1.jpg" width="70%" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="text-wrapper">
                                <div>
                                    <h2 className="title-hl wow fadeInLeft" data-wow-delay="0.3s">Build a relevant prospecting directory</h2>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6">
                                            <div className="features-box wow fadeInLeft" data-wow-delay="0.3s">
                                                <div className="features-icon">
                                                    <i className="lni-layers"></i>
                                                </div>
                                                <div className="features-content">
                                                    <h4>Create List</h4>
                                                    <p>Keep your prospects in separate list.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="testimonial" className="testimonial section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                            <div id="testimonials" className="owl-carousel wow fadeInUp" data-wow-delay="1.2s">
                                <div className="item">
                                    <div className="testimonial-item">
                                        <div className="content">
                                            <p className="description">Awesome extension! saves me a lot of time with work. Thanks to the developers of this software and of course the chat support as well, the chat support team has been really excellent and they helped me a lot with my concerns.</p>
                                        </div>
                                        <div className="img-thumb">
                                            <img src="assets/img/testimonial/img5.png" alt="" />
                                        </div>
                                        <div className="info">
                                            <h2><a href="./">Maria Cole.</a></h2>
                                            <h3><a href="./">Sales Hacker</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="Subscribes" className="subscribes section-padding">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-10 col-lg-5">
                            <h4 className="wow fadeInUp" data-wow-delay="0.3s">Stay upto date, join our newsletter</h4>
                            <p className="wow fadeInUp" data-wow-delay="0.6s"></p>
                            <form>
                                <div className="subscribe wow fadeInDown" data-wow-delay="0.3s">
                                    <input type="Email" className="form-control" name="email" placeholder="Email Address" />
                                    <button className="btn-submit" type="submit"><i className="lni-arrow-right"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section id="pricing" className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-xs-12 active" style={{ margin: "0 auto" }}>
                            <div className="table wow fadeInLeft" data-wow-delay="1.2s">
                                <div className="title">
                                    <h3>Basic</h3>
                                </div>
                                <div className="pricing-header">
                                    <p className="price-value">FREE</p>
                                </div>
                                <ul className="description">
                                    <li>100 Email Credits</li>
                                    <li>Up to 1,000 recipients</li>
                                    <li>Unlimited Email accounts</li>
                                    <li>CSV export</li>
                                </ul>
                                <a className="btn btn-common"
                                    href="https://chrome.google.com/webstore/detail/profile-locator/mhfkhmmkacamkgefbeofnimkemngjnda?hl=en&gl=US"
                                    rel="noopener noreferrer"
                                    target="_blank">Get It</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="subscribes section-padding">
                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12">
                            <h4 className="wow fadeInUp" data-wow-delay="0.3s">Start generating leads now with 100 free email credits / month</h4>
                            <p className="wow fadeInUp" data-wow-delay="0.6s"></p>
                            <div className="tcenter">
                                <a
                                    href="https://chrome.google.com/webstore/detail/profile-locator/mhfkhmmkacamkgefbeofnimkemngjnda?hl=en&gl=US"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="btn btn-common">Download Chrome Extension</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="contact" className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-header text-center">
                                <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">Contact</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row contact-form-area wow fadeInUp" data-wow-delay="0.4s">
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <div className="contact-block">
                                <h2>Contact Form</h2>
                                <form id="contactForm" method="POST" action="https://formspree.io/contact@profilelocator.com">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="name" name="name" placeholder="Name" required data-error="Please enter your name" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" placeholder="Email" id="email" className="form-control" name="email" required data-error="Please enter your email" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" placeholder="Subject" name="subject" id="msg_subject" className="form-control" required data-error="Please enter your subject" />
                                                <div className="help-block with-errors"></div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <textarea className="form-control" name="message" id="message" placeholder="Your Message" rows="5" data-error="Write your message" required></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div>
                                            <div className="submit-button">
                                                <button className="btn btn-common" id="form-submit" type="submit">Send Message</button>
                                                <div id="msgSubmit" className="h3 text-center hidden"></div>
                                                <div className="clearfix"></div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-sm-12">
                            <div className="contact-right-area wow fadeIn">
                                <h2>Get In Touch</h2>
                                <div className="contact-right">
                                    <div className="single-contact">
                                        <div className="contact-icon">
                                            <i className="lni-map-marker"></i>
                                        </div>
                                        <p>BREA, CA</p>
                                    </div>
                                    <div className="single-contact">
                                        <div className="contact-icon">
                                            <i className="lni-envelope"></i>
                                        </div>
                                        <p><a href="mailto:contact@profilelocator.com">contact@profilelocator.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
}
export default MainContainer;