import React from "react";

const Navigation = (props) => {
   return (
      <header id="header-wrap">
         <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar indigo">
            <div className="container">
               <div className="navbar-header">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
                     <span className="navbar-toggler-icon"></span>
                     <span className="icon-menu"></span>
                     <span className="icon-menu"></span>
                     <span className="icon-menu"></span>
                  </button>
                  <a href="/" className="navbar-brand">
                     <img src="assets/img/logo.png" alt="" />
                  </a>
               </div>
               <div className="collapse navbar-collapse" id="main-navbar">
                  <ul className="navbar-nav mr-auto w-100 justify-content-left clearfix">
                     <li className="nav-item active">
                        <a className="nav-link" href="#home">
                           Home
                       </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#feature">
                           Features
                       </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#testimonial">
                           Testimonials
                       </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#pricing">
                           Pricing
                       </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="#contact">
                           Contact
                       </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" href="https://app.profilelocator.com">
                           Login
                       </a>
                     </li>
                  </ul>
               </div>
            </div>
            <ul className="mobile-menu navbar-nav">
               <li>
                  <a className="page-scroll" href="#home">
                     Home
                 </a>
               </li>
               <li>
                  <a className="page-scroll" href="#feature">
                     Features
                 </a>
               </li>
               <li>
                  <a className="page-scroll" href="#testimonial">
                     Testimonials
                 </a>
               </li>
               <li>
                  <a className="page-scroll" href="#pricing">
                     Pricing
                 </a>
               </li>
               <li>
                  <a className="page-scroll" href="#contact">
                     Contact
                 </a>
               </li>
               <li>
                  <a className="page-scroll" href="https://app.profilelocator.com">
                     Login
                 </a>
               </li>
            </ul>
         </nav>
         <div id="home" className="hero-area-bg">
            <div className="overlay"></div>
            <div className="container">
               <div className="row">
                  <div className="col-md-12 col-sm-12">
                     <div className="contents text-center">
                        <h2 className="head-title wow fadeInUp">Find B2B emails<br />Build your most accurate leads directory</h2>
                        <div className="header-button wow fadeInUp" data-wow-delay="0.3s">
                           <a
                              href="https://chrome.google.com/webstore/detail/profile-locator/mhfkhmmkacamkgefbeofnimkemngjnda?hl=en&gl=US"
                              rel="noopener noreferrer"
                              target="_blank"
                              className="btn btn-common"
                           >Download Chrome Extension</a>
                        </div>
                     </div>
                     <div className="img-thumb text-center wow fadeInUp" data-wow-delay="0.6s">
                        <img className="img-fluid" src="assets/img/ep.jpg" alt="" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header>
   );
}

export default Navigation;