import React from "react";
const Footer = (props) => {
    return (
        <>
            <footer id="footer" className="footer-area section-padding">
                <div className="container">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.2s">
                                <div className="footer-logo mb-3">
                                    <img src="assets/img/logo.png" alt="" />
                                </div>
                                <p>Perfect solution to build your email prospects list on the go.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.6s">
                                <h3 className="footer-titel">About</h3>
                                <ul>
                                    <li>
                                        <a
                                        href="https://chrome.google.com/webstore/detail/profile-locator/mhfkhmmkacamkgefbeofnimkemngjnda?hl=en&gl=US"
                                        >Chrome Extension</a></li>
                                    <li><a href="/privacy-policy.html">Privacy Policy</a></li>
                                    <li><a href="/t_and_c.html">Terms and Conditions</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 wow fadeInUp" data-wow-delay="0.8s">
                                <h3 className="footer-titel">Find us on</h3>
                                <div className="social-icon">
                                    <a className="facebook" href="./"><i className="lni-facebook-filled"></i></a>
                                    <a className="twitter" href="./"><i className="lni-twitter-filled"></i></a>
                                    <a className="instagram" href="./"><i className="lni-instagram-filled"></i></a>
                                    <a className="linkedin" href="./"><i className="lni-linkedin-filled"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <section id="copyright">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>Copyright © 2019 Profile Locator All Right Reserved</p>
                        </div>
                    </div>
                </div>
            </section>

            <a href="/" className="back-to-top">
                <i className="lni-arrow-up"></i>
            </a>
        </>
    );
}

export default Footer;